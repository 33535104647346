@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';

.InventoryCardWrapper {
  .InventoryCard {
    /*
      Need more space in the bottom for the box-shadow of cards.bpk-card to show.
      Without extra space, the bottom shadow was cropped because
      overflow:hidden is necessary for the container (DesktopScrollContainer)
    */
    $card-margin: tokens.bpk-spacing-base() * 0.75;
    $card-margin-bottom: tokens.bpk-spacing-base();

    margin: $card-margin $card-margin $card-margin-bottom $card-margin;

    @include breakpoints.bpk-breakpoint-mobile {
      width: tokens.$bpk-one-pixel-rem * 312;
      margin: 0;
    }

    .hotelImage {
      max-height: tokens.$bpk-one-pixel-rem * 116;
      overflow: hidden;

      @include breakpoints.bpk-breakpoint-mobile {
        width: tokens.$bpk-one-pixel-rem * 312;
        overflow: hidden;
      }

      img {
        border-radius: tokens.$bpk-border-radius-md tokens.$bpk-border-radius-md
          0 0;
        object-fit: cover;

        @include breakpoints.bpk-breakpoint-mobile {
          width: tokens.$bpk-one-pixel-rem * 312;
          object-fit: cover;
        }
      }
    }

    .hotelInfo {
      height: tokens.$bpk-one-pixel-rem * 108;
      padding: tokens.bpk-spacing-base() tokens.bpk-spacing-base() 0;
    }

    .hotelNameAndRating {
      display: flex;
      justify-content: space-between;
      white-space: nowrap;
    }

    .hotelTitle {
      color: tokens.$bpk-text-primary-day;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-inline-end: tokens.bpk-spacing-md();
    }

    .distanceFromCentre {
      margin-bottom: tokens.bpk-spacing-md();
      flex-direction: row;
      color: tokens.$bpk-text-primary-day;
      text-align: start;
    }

    .tripAdvisorImage {
      height: tokens.bpk-spacing-base();
      margin-right: -(tokens.bpk-spacing-sm());
      margin-bottom: tokens.$bpk-one-pixel-rem * 2;
      margin-left: -(tokens.bpk-spacing-sm());
      vertical-align: middle;
    }

    .tripAdvisorRating {
      flex-direction: row;
      text-align: left;
    }

    .hotelPrice {
      height: tokens.bpk-spacing-lg() * 3;
      padding: tokens.bpk-spacing-base();
      border-top: tokens.$bpk-one-pixel-rem solid tokens.$bpk-line-day;
      text-align: right;
    }
  }
}
