@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';

.Staycation {
  @include breakpoints.bpk-breakpoint-mobile {
    > div
      > [class*='BpkMobileScrollContainer_bpk-mobile-scroll-container']::before,
    > div
      > [class*='BpkMobileScrollContainer_bpk-mobile-scroll-container']::after {
      display: none;
    }
  }

  &__cityTabs {
    margin: tokens.$bpk-one-pixel-rem * 18 0 tokens.$bpk-one-pixel-rem * 12;

    @include breakpoints.bpk-breakpoint-mobile {
      margin-inline: tokens.bpk-spacing-base() * -1;
    }
  }

  [class*='BpkMobileScrollContainer_bpk-mobile-scroll-container__inner'] {
    display: flex;
  }

  .mobile_scroll_container_body {
    display: flex;
    gap: tokens.$bpk-one-pixel-rem * 12;

    @include breakpoints.bpk-breakpoint-mobile {
      padding-inline: tokens.bpk-spacing-base();
    }
  }
}
